/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Injectable } from '@angular/core';
import {
  LocalNotificationPendingList,
  LocalNotifications,
  Schedule
} from '@capacitor/local-notifications';
import { GlobalConstants } from '@constants/global-constants';
import { LOCAL_NOTIFICATION_INTERVAL } from '@models/notification-type';
import { NetworkStatus } from '@models/synchronization/network-status';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { DeviceService } from './device.service';
import { NotificationService } from './notification/notification.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class LocalNotificationService {
  private readonly dailyNotificationSchedule: Schedule = {
    allowWhileIdle: true,
    on: {
      hour: 19,
      minute: 0
    }
  };

  private readonly weeklyNotificationSchedule: Schedule = {
    allowWhileIdle: true,
    on: {
      weekday: 2,
      hour: 7,
      minute: 0
    }
  };

  constructor(
		private notificationService: NotificationService,
		private translateService: TranslateService,
		private sessionService: SessionService,
    private logger: NGXLogger,
    private deviceService: DeviceService
  ) {
    if (this.deviceService.isMobile && !this.deviceService.isMobileWeb) {
      if (this.sessionService.getSession()) {
        this.logger.info('Session already exists. Setting up local notifications.');
        this.initializeLocalNotifications();
      }
    }
  }

  public initializeLocalNotifications() {
    NetworkStatus.waitForOnlineStatus().subscribe(() => {
      this.notificationService.getLocalNotificationConfiguration().then(
        (response) => {
          const notificationOption = response[0].interval;
          const isLocalNotificationEnabled = response[0].isEnabled;
          if (isLocalNotificationEnabled) {
            if (notificationOption === LOCAL_NOTIFICATION_INTERVAL.DAILY) {
              this.logger.info('Scheduling DAILY reminder notifications.');
              this.scheduleDailyLocalNotification();
            } else if (notificationOption === LOCAL_NOTIFICATION_INTERVAL.WEEKLY) {
              this.logger.info('Scheduling WEEKLY reminder notifications.');
              this.scheduleWeeklyLocalNotification();
            }
          } else {
            this.cancelAllNotifications();
          }
        }).catch(error => {
          this.logger.error('Unable to fetch local notification configuration in LocalNotification service: ', error);
        });
    });
  }

  async scheduleDailyLocalNotification() {
    this.cancelAllNotifications();
    const body = this.getNotificationBody(true);
    await LocalNotifications.requestPermissions();
    await LocalNotifications.schedule({
      notifications: [
        {
          smallIcon: GlobalConstants.localNotificationIconLink,
          title: 'Site Diary',
          body: body,
          id: 1,
          schedule: this.dailyNotificationSchedule
        }
      ]
    });
  }

  async scheduleWeeklyLocalNotification() {
    this.cancelAllNotifications();
    const body = this.getNotificationBody(false);
    await LocalNotifications.requestPermissions();
    await LocalNotifications.schedule({
      notifications: [
        {
          smallIcon: GlobalConstants.localNotificationIconLink,
          title: 'Site Diary',
          body: body,
          id: 2,
          schedule: this.weeklyNotificationSchedule
        }
      ]
    });
  }

  getNotificationBody(isDaily: boolean): string {
    const currentUser = this.sessionService.getCurrentUser();
    return currentUser ?
      this.getNotificationString(`local-notification.configuration.${isDaily ? 'daily' : 'weekly'}`, { username: currentUser.firstName}) :
      this.getNotificationString(`local-notification.configuration.${isDaily ? 'daily' : 'weekly'}.nameless`);
  }

  getNotificationString(msg: string, translationParams?: Object): string {
    let notificationBody;
    this.translateService.get(['notification', msg], translationParams).subscribe(
      message => {
        notificationBody = message;
      });
    return notificationBody[msg];
  }

  async cancelAllNotifications(): Promise<void> {
    const localNotificationsPending = await LocalNotifications.getPending();
    if (localNotificationsPending.notifications.length !== 0) {
      LocalNotifications.cancel(localNotificationsPending);
    }
  }
}
