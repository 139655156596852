<ng-container [formGroup]="parentFormGroup">
  <ng-select [labelForId]="controlName" [formControlName]="controlName" class="full-width-labels ng-dropdown" [ngClass]="{'limit-width' : !isFieldEmpty() && isSiteDiary}" [items]="items"
    bindLabel="name" [compareWith]="compareByIdAndPopulate" [isOpen]="isReadOnly ? false : null" [multiple]="true" [searchable]="!isReadOnly" [closeOnSelect]="false"
    [clearOnBackspace]="false" [openOnEnter]="false" [loading]="isLoading" [placeholder]="placeholder" (add)="onAdd($event)" (close)="onClose()">

    <ng-template ng-label-tmp let-item="item" let-clear="clear">
      <div id="selected-element" class="selected-element-background selected-element-chip">
        <div class="title">{{ item.name }} &nbsp;&nbsp;
          <div *ngIf="!isReadOnly" class="icon">
            <ion-icon name="custom-close" (click)="clear(item)"></ion-icon>
          </div>
          <p *ngIf="isAssetLabour" class="job-role">{{ item.jobRole }}</p>
        </div>
        <div class="quantity">
          <label>
            {{'label.quantity' | translate}}
            <span *ngIf="!isReadOnly; else displayNumberTemplate">
              <input type="number" step="any" min="0" name="quantity" [readOnly]="isReadOnly" [(ngModel)]="item.amount"
                [ngModelOptions]="{standalone: true}" (keyup)='this.manuallyUpdateTaskItem();'
                (paste)="this.manuallyUpdateTaskItem();" (ngModelChange)="markFormControlAsTouched(); markFormControlAsDirty(); this.manuallyUpdateTaskItem();"
                (input)="limitDecimal($event, item)">
            </span>
            <ng-template #displayNumberTemplate>
              <ng-container *ngIf="item.amount">
               <span class="amount">{{item.amount}}</span>
              </ng-container>
            </ng-template>
          </label>
        </div>
        <div *ngIf="hasDuration" class="duration">
          <label *ngIf="hasDuration">
            <app-duration-picker [readOnly]="isReadOnly" [duration]="item.duration"
              (durationChange)="item.duration = $event; markFormControlAsTouched(); markFormControlAsDirty(); updateDuration();">
            </app-duration-picker>
          </label>
        </div>
        <div *ngIf="!hasDuration" class="duration">
          <label>
            <span *ngIf="item.quantityUnit">{{ "label.unit" | translate }}: {{ this.quantityUnits[item.quantityUnit] }}</span>
          </label>
        </div>
      </div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-item$="item$">
      <div>
        <ion-grid>
          <ion-row>
            <ion-col size="auto">
              <input type="checkbox" [checked]="item$.selected" />
            </ion-col>
            <ion-col>
              <p>{{ item.name }}</p>
              <p *ngIf="isAssetLabour" class="job-role">{{ item.jobRole }}</p>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ng-template>
  </ng-select>
</ng-container>
