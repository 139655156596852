<div class="custom-field-web" [ngClass]="{'empty-field': !parentFormGroup.value.location}" *ngIf="!deviceIsMobile" [class.hide-fields]="!isEdited && isEmpty('location')">
  <app-select-multiple [parentFormGroup]="parentFormGroup" controlName="location" [items]="locations"
  [isReadOnly]="!isEdited" [isLoading]="!locationsLoaded" [multiple]="false"
  placeholder="&nbsp;+&nbsp;&nbsp;&nbsp;{{ 'events.edit.field.location.label' | translate }}"
  *ngIf="!parentFormGroup.value.location">
  </app-select-multiple>
  <div [formGroup]="parentFormGroup">
    <ng-select [formControlName]="'location'" [items]="locations" bindLabel="name" [multiple]="false"
      [searchable]="false" [isOpen]="false" [clearOnBackspace]="false" [openOnEnter]="false"
      *ngIf="parentFormGroup.value.location">
      <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <div class="selected-element">
          <span>{{ parentFormGroup.value.location.name }}</span>
          <button class="close-button" ion-button icon-only (click)="onClearLocation()" *ngIf="isEdited">
            <ion-icon name="custom-close"></ion-icon>
          </button>
        </div>
      </ng-template>
    </ng-select>
  </div>
</div>

<div *ngIf="deviceIsMobile && !isMobileEventPreviewComponent" class="custom-field-mobile">
  <ion-item lines="none" class="card-items"
    (click)="openItemSelectorPopup(locations, parentFormGroup.value.location ? [parentFormGroup.value.location] : [], 'location', 'single')">
    <ion-input
      readonly
      [placeholder]="'mobile-placeholder-location-site' | translate"
      *ngIf="!parentFormGroup.value.location">
    </ion-input>
    <ion-icon name="location-pin" color="primary" slot="start"></ion-icon>
    <span class="assets-container" *ngIf="parentFormGroup.value.location">
        {{ parentFormGroup.value.location.name }}
    </span>
  </ion-item>
</div>

<div *ngIf="deviceIsMobile && isMobileEventPreviewComponent" class="custom-event-preview-mobile">
  <ion-row *ngIf="event.locationObject">
    <ion-col>
      <header class="label">{{customField.title}}</header>
    </ion-col>
    <ion-col>
      <p>
        {{ event.location }}
      </p>
    </ion-col>
  </ion-row>
</div>

