import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DatabaseService } from './shared/database.service';

@Injectable({
  providedIn: 'root'
})
export class PendingAttachmentService {

  // Holds list of pending attachment IDs waiting for upload
  private pendingAttachments = new BehaviorSubject<string[]>([]);
  public storedImages: {[key: string]: string} = {};

  constructor(
    private databaseService: DatabaseService
  ) {
    this.setPendingAttachments();
   }

  /**
   * Fetch all attachments which are yet to be uploaded.
   */
  private async setPendingAttachments() {
    const attachments: string[] = [];
    const db = await this.databaseService.getUserDB();
    if (db) {
      await db.queue.filter(action => action.action.type === 'Attachment').toArray().then(actions =>
        actions.forEach(action => {
          attachments.push(action.action.payload.id);
        })
      );
      this.pendingAttachments.next(attachments);
    }
  }

  public pushAttachment(attachmentID: string) {
    // Add attachment ID to pending attachment list. This attachment will not be rendered until it's uploaded.
    // (unless the attachment is present locally in AttachmentService).
    this.pendingAttachments.next([...this.pendingAttachments.value, attachmentID]);
  }

  public popAttachment(attachmentID: string) {
    const filteredAttachments = this.pendingAttachments.value.filter(attachmentId => attachmentId !== attachmentID);
    this.pendingAttachments.next(filteredAttachments);
  }

  public getPendingAttachments(): Observable<string[]> {
    return this.pendingAttachments.asObservable();
  }
}
