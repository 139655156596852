<div *ngIf="!deviceIsMobile" class="custom-field-web select-multiple" [ngClass]="{'empty-field': parentFormGroup.value.labours.length === 0, 'top-margin': isEdited}" [class.hide-fields]="!isEdited && isEmpty('labours')">
  <app-select-multiple-assets [parentFormGroup]="parentFormGroup" controlName="labours" [items]="labours" [isSiteDiary]="true"
                [isReadOnly]="!isEdited" [isLoading]="!laboursLoaded" [hasDuration]="true" [assetCategory]="getAssetCategory().LABOURS"
                placeholder="&nbsp;+&nbsp;&nbsp;&nbsp;{{ 'events.edit.field.labour.label' | translate }}">
  </app-select-multiple-assets>
</div>

<div *ngIf="deviceIsMobile && !isMobileEventPreviewComponent" class="custom-field-mobile">
  <ion-item
    class="card-items"
    lines="none"
    (click)="openItemSelectorPopup(labours, parentFormGroup.value.labours, 'labours', 'multiple_with_quantity_and_duration')">
    <ion-input
      type="text"
      readonly
      [placeholder]="'mobile-placeholder-labours' | translate"
      *ngIf="parentFormGroup.value.labours.length === 0">
    </ion-input>
    <ion-icon name="engineer" color="primary" slot="start"></ion-icon>
    <span class="assets-container" *ngIf="parentFormGroup.value.labours.length > 0">
        {{ parentFormGroup.value.labours | listToLabelList | join }}
    </span>
  </ion-item>
</div>

<div *ngIf="deviceIsMobile && isMobileEventPreviewComponent" class="custom-event-preview-mobile">
  <ion-grid *ngIf="labours.length > 0">
    <ion-row>
      <ion-col>
        <header class="label">{{customField.title}}</header>
      </ion-col>
    </ion-row>
    <ion-row *ngFor="let labour of labours; let index" class="people-row">
      <ion-col>
        <div class="people-pill-labour">
          <ion-grid [fixed]="true">
            <ion-row class="ion-justify-content-between">
              <ion-col size="7">
                <p>{{labour.name}}</p>
                <p class="job-role">{{ labour.jobRole }}</p>
              </ion-col>
              <ion-col class="text-right" size="auto">
                <span>
                  {{ 'plugins.select.qty' | translate }}  {{labour.amount}}
                </span>
              </ion-col>
              <ion-col class="text-right" size="auto">
                <span>
                  <ion-icon mode="md" name="time-outline"></ion-icon> {{ timeConvert(labour.duration) }}
                </span>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>

