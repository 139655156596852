export const environment = {
  name: 'development',
  production: false,
  webappUrl : 'https://app.dev.siteproductivity.com',
  apiUrl: 'https://app.dev.sitediary.com/api',
  wsUrl: 'wss://app.dev.sitediary.com/api/ws',  // Should be changed to app.dev.sitediary.com after VM fix
  intercomId: 'pitlq3yk',
  path: '/', // Assets base URL, example: 'app-dev.mobilesitediary.com/beta/assets/...'
  stripePublishableKey: 'pk_test_8e89iRsCuQiCZjIK45cs20Pp',
  fullCalendarLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives', // Testing key to be used only in development.
  googleMapsKey: 'AIzaSyAkDzq2SNAaQqx4cSNL7cOtLFr-7qZHWK0',
  sentryKey: 'https://46bd33bfb4074bdf8738a6271aadfabb@o4504158714462208.ingest.sentry.io/4504195398041600',
  ngxLoggerLevel: 1,
};
