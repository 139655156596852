<div *ngIf="!deviceService.isMobile && (showErrorMessage || isVerificationCodeSent)" class="overlay webapp otp-verification-modal-webapp">
  <div class="modal-dialog desktop">
    <div class="modal-content">
      <div *ngIf='!showErrorMessage' class="container">
        <h3>{{ "otp.verification.enter_the_verification_code" | translate }}</h3>
        <p>{{ "otp.verification.sent_verification_code" | translate }}</p>
        <p>{{ userPhoneNumber | phoneNumberMask}}</p>
      </div>
      <div class="modal-body" [ngClass]="{ 'error-message': showErrorMessage }">
        <h3 *ngIf="showErrorMessage">{{ errorMessage }}</h3>
        <div *ngIf="!showErrorMessage" class="otp-field">
          <div class="otp-container">
            <p class="label">{{ "otp.verification.enter_code" | translate }}</p>
              <div class="otp-input-container">
                <div *ngFor="let digit of otp; let i = index">
                  <input
                  #otpInput
                  class="otp-input"
                  id="otp_{{i}}"
                  type="number"
                  (keydown)="preventInput($event)"
                  (keyup)="onOtpInput($event, i+1)"
                  pattern="[0-9]*"
                  />
                </div>
          </div>
              <span *ngIf="isIncorrectOtp" class="validation-error">{{ 'otp.verification.invalid_otp' | translate }}</span>
          </div>
            <div class="resend-button-label">
              <button *ngIf="!timerRunning()" class="btn resend-button" [disabled]="isResendDisabled" (click)="requestOtp()">{{ "otp.verification.resend_button" | translate }}</button>
              <p *ngIf="timerRunning()">{{ 'otp.verification.resend_available_in' | translate }} <b>{{ timeLeft }}</b></p>
            </div>
        </div>
      </div>
      <div class="modal-footer">
        <button *ngIf="!showErrorMessage" (click)="verifyOtp()" type="button" [disabled]="!isAValidOtp()" class="btn btn-primary">{{ 'otp.verification.continue_button' | translate }}</button>
        <button *ngIf="isSessionTimeout || allowRetry" (click)="requestOtp()" type="button" class="btn btn-primary">{{ 'otp.verification.try_again' | translate }}</button>
        <button *ngIf="isLogoutButtonEnabled" (click)="logout()" type="button" class="btn btn-primary logout-button">{{ 'header.log_out' | translate }}</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="deviceService.isMobile && (showErrorMessage || isVerificationCodeSent)" class="full-screen mobile">
  <ion-header mode="ios" *ngIf="!showErrorMessage">
    <ion-toolbar mode="ios" color="primary">
    </ion-toolbar>
  </ion-header>
  <div *ngIf="!showErrorMessage" class="container">
    <h3>{{ "otp.verification.enter_the_verification_code" | translate }}</h3>
    <p>{{ "otp.verification.sent_verification_code" | translate }}</p>
    <p>{{ userPhoneNumber | phoneNumberMask}}</p>
    <div class="otp-field">
      <div class="label">
        <label class="left">{{ "otp.verification.enter_code" | translate }}</label>
      </div>
      <div class="validation-error">
        <div class="input-field">
          <input #mobileInputText [(ngModel)]="enteredOTP" (keyup.enter)="verifyOtp()" (keydown.enter)="$event.preventDefault()"/>
        </div>
        <div>
          <button (click)="verifyOtp()" type="button" [disabled]="!isAValidOtp()" class="btn btn-primary">{{ 'btn.confirm' | translate }}</button>
        </div>
      </div>
      <span *ngIf="isIncorrectOtp" class="validation-error">{{ 'otp.verification.invalid_otp' | translate }}</span>
    </div>
    <div class="resend-section">
      <button class="btn resend-button" [disabled]="isResendDisabled" (click)="requestOtp()">{{ "otp.verification.resend_button" | translate }}</button>
      <p *ngIf="timeoutUntilResend">{{"otp.verification.next_resend_at.mobile_message" | translate:{time: timeoutUntilResend | date: 'mediumTime'} }}</p>
    </div>
  </div>
  <div class="error-messages">
    <h3 *ngIf="showErrorMessage">{{ errorMessage }}</h3>
  </div>
  <div class="modal-footer" [ngClass]="{ 'error-message-no-footer': showErrorMessage && !isIncorrectOtp }">
    <button *ngIf="isSessionTimeout || allowRetry" (click)="requestOtp()" type="button" class="btn btn-primary">{{ 'otp.verification.try_again' | translate }}</button>
    <button *ngIf="isLogoutButtonEnabled" (click)="logout()" type="button" class="btn btn-primary logout-button">{{ 'header.log_out' | translate }}</button>
  </div>
</div>
